<template>
  <div class="contents">
    <div class="card shadow">
      <div class="card-body mt10">

        <!-- 검색 -->
        <div class="credit-table-block pl20 pt20 pb20 mb20">
          <label  for="" class="tx_rt ml30 mr10 bold"><i class="fas fa-caret-right fs14"></i> 개인/법인</label>
          <base-select-box
              :class="selectBoxClass2"
              :id="'selectBoxCorp'"
              :items="corpTypeList"
              @toParent:selectValue="val => getBankCompanyList(val)"
              @toParent:setValue="val => corpGbn = val"/>

          <label  for="" class="tx_rt ml20 mr10 bold"><i class="fas fa-caret-right fs14"></i> 금융기관</label>
          <base-select-box
              :class="selectBoxClass1"
              :id="'selectBoxBank'"
              :items="bankCompanyList"
              v-model="bankCompanyList"
              @toParent:selectValue="val => selectBankCompany(val)"
              @toParent:setValue="val => bankGbn = val"/>

          <button type="button" @click="authInquriy" class="btn btn-darkgrey ml20 mr20 w120">조회</button>
        </div>

        <!-- 은행사 인증서명 테이블 -->
        <div class="btnwrap-left">
          <button type="button" @click="openLoginIdPwdPopup" class="btn btn-primary pr20 pl20">인증정보 추가</button>
        </div>
        <div class="btnwrap-right">
          <button type="button" @click="openSimpleAccountRegPopup" class="btn btn-info pr20 pl20 mr5"><i class="fas fa-plus-square txt-white pr5"></i> 간편 계좌 등록</button>
          <button type="button" @click="deleteAuthList" class="btn btn-black pr20 pl20">일괄삭제</button>
        </div>
        <table class="table01">
          <caption>은행사 인증정보 표</caption>
          <colgroup>
            <col v-for="(item, index) in tableData_attr.heads" :key="index" :style="item.style">
          </colgroup>
          <thead>
          <tr>
            <th v-for="(item, index) in tableData_attr.heads" :key="index" class="tx_cnt" v-html="item.col" >{{item.col}}</th>
          </tr>
          </thead>
          <tbody v-if="tableData_attr.items.length > 0">
          <tr v-for="(item, index) in tableData_attr.items" :key="index">
            <td class="tx_cnt">
              <label><input type="checkbox" :value="item.idx" v-model="delRequestAuthList"></label>
            </td>
            <td class="tx_cnt">{{item.scrapSiteName}}</td>
            <td class="tx_cnt">{{item.corpGbn}}</td>
            <td class="tx_cnt">
              <span :class="item.authType =='ID' ? 'label-blue':'label-green' ">{{item.authType == "ID" ? "ID/PW" : "인증서"}}</span><!-- 인증서 -->
            </td>
            <td class="tx_cnt">{{item.authId}}</td>
            <td class="tx_cnt">{{item.regDt}}</td>
            <td class="tx_cnt txt-primary" v-if="item.status == 'VALID'">
              <!-- 성공 -->
              정상
            </td>
            <td class="tx_cnt" v-else>
              <!-- 실패 -->
              <div class="tooltip">
                <span class="tooltip-cursor txt-danger">오류</span>
                <span class="tooltiptext form-group has-error tx_cnt">
										<i class="fa fa-times-circle-o has-error"/>{{item.statusDesc}}
									</span>
              </div>
            </td>
            <td class="tx_cnt">{{item.financeCnt}}</td>
            <td class="tx_cnt">
              <button v-if="item.authType == 'CERT'" type="button" @click="openLoginDetailsInfoPopup_cert(item.idx)" class="btn btn-block btn-default btn-sm mr5 w100">인증정보 관리</button>
              <button v-if="item.authType == 'ID'" type="button" @click="openLoginDetailsInfoPopup_idpwd(item.idx)" class="btn btn-block btn-default btn-sm mr5 w100">인증정보 관리</button>
              <button type="button" @click="openAccountMngPopup(item.idx)" class="btn btn-block btn-default btn-sm">계좌관리</button>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- //은행사 인증서명 테이블 -->
        <base-pagination
            :prefix="'/auth/bank'"
            :pTotalListItemCount="authCount"
            :pageInit="pageInit"
            @toParent:changePage="(val) => pageIndex = val"
        />
      </div>
    </div>

    <!-- Modal Popup - 인증정보 추가 -->
    <base-auth-reg-popup
        :popupTitle=authRegTitle
        :visible="visible1"
        :financeType="'BANK'"
        @toParent:reInqury="authInquriy()"
        @toParent:visible="val => visible1=val"
    >
    </base-auth-reg-popup>
    <!-- //Modal Popup - 인증정보 추가 -->

    <!-- Modal Popup - 계좌번호 등록/관리 선택 -->
    <div class="w3-container">
      <base-account-reg-popup
          :visible="visible2"
          :idx="accountInfoTable_attrs.idx"
          :bankNm="accountInfoTable_attrs.bankNm"
          :authType="accountInfoTable_attrs.authType"
          :authNm="accountInfoTable_attrs.authNm"
          :bizKind="accountInfoTable_attrs.bizKind"
          :heads="accountInfoTable_attrs.heads"
          :bodys="accountInfoTable_attrs.bodys"
          @toParent:visible="val => closeAccountRegPopup(val)"
      />
    </div>
    <!-- //Modal Popup - 계좌번호 등록/관리 선택 -->

    <!-- Modal Popup - 인증서 상세정보 -->
    <div class="w3-container">
      <base-login-details-info-popup
          :visible="visible3"
          :scrapSiteType="loginDetailsInfo_cert_attrs.scrapSiteType"
          :scrapSiteName="loginDetailsInfo_cert_attrs.scrapSiteName"
          :authType="loginDetailsInfo_cert_attrs.authType"
          :bizKind="loginDetailsInfo_cert_attrs.bizKind"
          :gubun="loginDetailsInfo_cert_attrs.gubun"
          :popupTitle="loginDetailsInfo_cert_attrs.popupTitle"
          :caption="loginDetailsInfo_cert_attrs.caption"
          :heads="loginDetailsInfo_cert_attrs.heads"
          :bodys="loginDetailsInfo_cert_attrs.bodys"
          :idx="loginDetailsInfo_cert_attrs.idx"
          :updateOptions="executeUpdateOptions"
          @toParent:visible="val => visible3=val"
          @toParent:executeCertUpdate="idx => executeCertUpdate(idx)">

        <!-- slot  -->
        <div class="btnwrap clear pt10" slot= "slot_btns">
          <!-- <button onclick="" type="button" class="btn btn-block btn-primary w115" >저장</button> -->
          <button @click="certUpdate()" type="button" class="btn btn-block btn-danger mr10 w120">인증서 교체</button>
        </div>
      </base-login-details-info-popup>
    </div>
    <!-- //Modal Popup - 인증서 상세정보 -->

    <!-- Modal Popup - ID/PW 상세 정보 -->
    <div class="w3-container">
      <base-login-details-info-popup
          :visible="visible4"
          :scrapSiteType="loginDetailsInfo_idpwd_attrs.scrapSiteType"
          :scrapSiteName="loginDetailsInfo_idpwd_attrs.scrapSiteName"
          :authType="loginDetailsInfo_idpwd_attrs.authType"
          :bizKind="loginDetailsInfo_idpwd_attrs.bizKind"
          :gubun="loginDetailsInfo_idpwd_attrs.gubun"
          :popupTitle="loginDetailsInfo_idpwd_attrs.popupTitle"
          :caption="loginDetailsInfo_idpwd_attrs.caption"
          :heads="loginDetailsInfo_idpwd_attrs.heads"
          :bodys="loginDetailsInfo_idpwd_attrs.bodys"
          :idx="loginDetailsInfo_idpwd_attrs.idx"
          :changeYn="pwdChangeYn"
          :updateOptions="executeUpdateOptions"
          @toParent:visible="(val) => {visible4=val; pwdChangeYn = val}"
          @toParnet:executePwdUpdate="(idx, pwd) => executePwdUpdate(idx, pwd)">

        <!-- slot  -->
        <div class="btnwrap clear pt10" slot= "slot_btns">
          <button v-if="pwdChangeYn" @click="pwdUpdate()" type="button" class="btn btn-block btn-primary w115">저장</button>
          <button v-if="!pwdChangeYn" @click="pwdChange()" type="button" class="btn btn-block btn-danger mr10 w120">패스워드 변경</button>
        </div>
      </base-login-details-info-popup>
    </div>
    <!-- //Modal Popup - ID/PW 상세 정보 -->

    <!-- Modal Popup - 간편 계좌 등록 -->
    <div class="w3-container">
      <base-simple-reg-popup
          :popupTitle="simpleCardTitle"
          :visible="visible5"
          :init="bankRegInit"
          :financeType="'BANK'"
          @toParent:visible="val => closeSimpleAccountRegPopup(val)" />
    </div>
    <!-- //Modal Popup - 간편 계좌 등록 -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
	name: 'PageAuthBank',

	data: () => ({
		prefix:"api/auth/bank",

		// init Constant 
		bankCompanyList : [],
		preselectValue : "개인",

		// Variable
		visible1: false,
		visible2: false,
		visible3: false,
		visible4: false,
		visible5: false,
		selectBoxClass1: 'form-control-inline w120',
		selectBoxClass2: 'form-control-inline w80',
		simpleCardTitle: '간편 계좌 등록',
		authRegTitle: '인증정보 추가',

		corpGbn : "",
		bankGbn : "",

		bankRegInit: false,
		delRequestAuthList : [],

		// Components attrs
		//인증서 인증정보 상세팝업
		loginDetailsInfo_cert_attrs: {
			scrapSiteType: "",
			scrapSiteName: "",
			authType: "",
			bizKind: "",
			gubun: 'cert',
			popupTitle: '인증서 상세 정보', 
			caption: '인증서 상세 정보 표',
			idx:"",
			heads: [
				{col: '', style: 'width:150px',},
				{col: '', style: '',},
			],
			bodys: [],
		},
		//idpw 인증정보 상세팝업
		loginDetailsInfo_idpwd_attrs: {
			scrapSiteType: "",
			scrapSiteName: "",
			authType: "",
			bizKind: "",
			gubun: 'idpwd',
			popupTitle: 'ID/PW 상세 정보',
			caption: 'ID/PW 상세 정보 표',
			idx:"",
			heads: [
				{col: '', style: 'width:150px',},
				{col: '', style: '',},
			],
			bodys: [],
		},
		pwdChangeYn: false,
		executeUpdateOptions:{},

		//계좌관리 팝업
		accountInfoTable_attrs:{
			idx: "",
			cardNm : "",
			authType : "", //CERT 혹은 ID
			bizKind : "", //PERSON 혹은 CORP
			authNm : "", //인증서명 혹은 웹 Id
			heads:[
				{col: '선택', style: 'width:50px'},
				{col: '계좌번호', style: ''},
				{col: '별칭', style: 'width:100px'},
				{col: '상태', style: 'width:90px'},
				{col: '', style: 'width:80px'},
			],
			bodys:[
				{
					idx: "",
					accountNo:"",
					accountAlias:"",
					status:"",
					statusDesc:"",
				}
			],
		},
		tableData_attr:{ 
			heads:[
				{col: '선택', style:'width:50px;'},
				{col: '금융기관', style:'width:95px;'},
				{col: '구분', style:'width:60px;'},
				{col: '인증수단', style:'width:80px;'},
				{col: '인증ID', style:''},
				{col: '등록일자', style:'width:100px;'},
				{col: '상태', style:'width:60px;'},
				{col: '등록계좌', style:'width:80px;'},
				{col: '관리', style:'width:210px;'},
			],
			items:[],
		},
		
		//pagination
		authCount: 0,
		pageIndex: 10,
		pageLimit: 10,
		pageInit: false, 

	}),
	watch:{
		visible5(val){
			if(val == false) this.bankRegInit = false;
		},
		pageIndex(){
			this.authInquriy();
		}
	},
	computed: {
		...mapState([
			'corpTypeList'
		])
	},
	methods: {
		selectBankCompany(){
			this.pageIndex = 10;
			this.pageInit = true;
			this.authInquriy();
		},
		getBankCompanyList(val){
			let that = this;
			
			let scrapSiteInfo = new Object();
			if(val != ''){
				scrapSiteInfo.bizKind = val;
			}

			this.$cAxios.post(this.prefix+"/finance/company/list/inquriy",JSON.stringify(scrapSiteInfo),{
				headers:{
					'Content-Type':'application/json'
				}
			})
			.then((res)=>{
				if(res.data != null){
					this.bankCompanyList = [];
					this.bankCompanyList.push({key:'',value:"전체"});
					for(var i = 0 ; i < res.data.length; i++){
						var resData = {
							key : (val == '' ? res.data[i].scrapSubCode : res.data[i].idx ),
							value : res.data[i].name
						};
						this.bankCompanyList.push(resData);
					}
					
				}

			}).catch((err)=>{
				console.log(err.response);
			}).finally(()=>{
				that.authInquriy();
			});
			
		},
		authInquriy(){
			let that = this;

			let corpGbn = document.getElementById("selectBoxCorp");
			this.corpGbn = corpGbn.options[corpGbn.selectedIndex].value;

			let bankGbn =  document.getElementById("selectBoxBank");
			this.bankGbn = bankGbn.options[bankGbn.selectedIndex].value;
			
			let param = new Object();
			if(corpGbn.selectedIndex != 0){
				param.bizKind = this.corpGbn;
			}
			if(corpGbn.selectedIndex > 0){
				param.idx = this.bankGbn;
			}else if(corpGbn.selectedIndex == 0){
				param.scrapSubCode = this.bankGbn;
			}

			param.limit = this.pageLimit;
			param.offset = (this.pageIndex - this.pageLimit);


			this.$cAxios.post(this.prefix+"/auth/list/inquriy/paging", JSON.stringify(param),{
				headers:{
					'Content-type':'application/json;'
				}
			})
			.then((res)=>{
				if(res.data == null){
					return false;
				}

				if(res.data.length == 0){
					this.authCount = 0;
					that.$set(that.tableData_attr, "items", []);
					return false;	
				}
				
				this.authCount = res.data[0].authCnt;

				let tableList = new Array();
				for(var i = 0; i < res.data.length; i++ ){
					
					let resData = {
						idx:res.data[i].idx,
						scrapSiteName: res.data[i].scrapSiteName,
						corpGbn: (res.data[i].bizKind == "PERSON" ? "개인" : "법인" ),
						authType: res.data[i].authType,
						authId: (res.data[i].authType == "ID"? res.data[i].authId : res.data[i].certNm),
						regDt:res.data[i].regDt.substring(0,10),
						status:res.data[i].status,
						statusDesc: res.data[i].statusDesc,
						financeCnt: res.data[i].financeCnt,
					}
					tableList.push(resData);
				}
				
				that.$set(that.tableData_attr, "items", tableList);
			})
			.catch(()=>{
				
			})
			.finally(()=>{
				this.delRequestAuthList = [];
				this.pageInit = false;
			});
		},
		deleteAuthList(){
			let delYn = confirm("삭제하시겠습니까");
			if(!delYn) return false;
			let authList = this.delRequestAuthList;
			if(!authList.length > 0) {
				alert("삭제할 인증정보를 선택해주세요.");
				return false;
			} 
			
			let paramList = new Array();
			for(var i = 0 ; i< authList.length; i++){
				let param = {};
				param.idx = authList[i];
				paramList.push(param);
			}

			this.$cAxios.post(this.prefix+"/auth/delete",JSON.stringify(paramList),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("삭제되었습니다.");
				this.authInquriy();
			}).catch((err)=>{
				if(err.response){
					alert("인증정보 삭제에 문제가 발생했습니다. 다시 시도해주세요.");
				}
			}).finally(()=>{
				this.delRequestAuthList = [];
			});
		},
		openLoginIdPwdPopup() {
			this.visible1 = true;
		},
		openAccountMngPopup(idx) {
			let authIdx = idx.toString();
			let param = {};
			param.idx = authIdx;
			this.$cAxios.post(this.prefix+"/account/list/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.accountInfoTable_attrs.idx = authIdx;
					this.accountInfoTable_attrs.bankNm = res.data.scrapSiteName;
					this.accountInfoTable_attrs.authType = res.data.authType;
					this.accountInfoTable_attrs.authNm = (res.data.authType == 'CERT'? res.data.certNm: res.data.authId);
					this.accountInfoTable_attrs.bizKind = res.data.bizKind;

					let accountList = new Array();
					let inquriyAccountList = res.data.scrapFinances;
					if(!inquriyAccountList.length){
						let accountInfo = {
							idx : "",
							accountNo : "",
							accountAlias : "",
							status : "",
							statusDesc : ""
						};
						accountList.push(accountInfo);
						this.$set(this.accountInfoTable_attrs,'bodys',accountList);
						return;
					}
					
					for(var i = 0; i < inquriyAccountList.length; i++){
						let accountInfo = {};
						accountInfo.idx = inquriyAccountList[i].idx.toString();
						accountInfo.accountNo = inquriyAccountList[i].financeNo;
						accountInfo.accountAlias = inquriyAccountList[i].financeAlias;
						accountInfo.status = inquriyAccountList[i].status;
						accountInfo.statusDesc = inquriyAccountList[i].statusDesc;
						accountList.push(accountInfo);
					}
					this.$set(this.accountInfoTable_attrs,'bodys',accountList);
				}
			}).finally(()=>{
				this.visible2 = true;
			});
			
		},
		openLoginDetailsInfoPopup_cert(val) {
			let that = this;

			let param = new Object();
			param.idx = val;
			param.authType = 'CERT';
			this.$cAxios.post(this.prefix+"/auth/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.loginDetailsInfo_cert_attrs.scrapSiteType = "BANK";
					this.loginDetailsInfo_cert_attrs.scrapSiteName = res.data.scrapSiteName;
					this.loginDetailsInfo_cert_attrs.authType = res.data.authType;
					this.loginDetailsInfo_cert_attrs.bizKind = res.data.bizKind;

					let resData = [
						{th: '인증서 ID', td:res.data.certNm},
						{th: '등록기관', td:res.data.certOrgNm},
						{th: '인증서 유효기간', td:res.data.validDateTerm},
						{th: '상태', td:(res.data.status == 'VALID'? '정상':'오류')},
					]
					that.$set(that.loginDetailsInfo_cert_attrs,"bodys",resData);
					that.loginDetailsInfo_cert_attrs.idx = val.toString();
				}
			}).catch(()=>{

			}).finally(()=>{
				this.visible3 = true;
			});
			
		},
		openLoginDetailsInfoPopup_idpwd(val) {
			let that = this;

			let param = new Object();
			param.idx = val;
			param.authType = "ID";
			this.$cAxios.post(this.prefix+"/auth/inquriy",JSON.stringify(param),{
				headers:{
					'Content-Type': 'application/json'
				}
			}).then((res)=>{
				if(res.data != null){
					this.loginDetailsInfo_idpwd_attrs.scrapSiteType = "BANK";
					this.loginDetailsInfo_idpwd_attrs.scrapSiteName = res.data.scrapSiteName;
					this.loginDetailsInfo_idpwd_attrs.authType = res.data.authType;
					this.loginDetailsInfo_idpwd_attrs.bizKind = res.data.bizKind;

					that.loginDetailsInfo_idpwd_attrs.bodys = [];

					let resData = [
						{th: '웹 아이디', td:res.data.authId},
						{th: '웹 패스워드', td:'******'},
						{th: '인증상태', td:(res.data.status == 'VALID'? '정상':'오류')},
						// {th: '상태상세', td:res.data.statusDesc},
					]
					that.$set(that.loginDetailsInfo_idpwd_attrs, "bodys", resData);
					that.loginDetailsInfo_idpwd_attrs.idx = val.toString();
				}
			}).catch(()=>{

			}).finally(()=>{
				this.visible4 = true;
			});

			
		},
		pwdChange(){
			if(this.pwdChangeYn) return false;
			this.pwdChangeYn = !this.pwdChangeYn;
		},
		pwdUpdate(){
			let options = {
				updateYN:true,
				type:'ID'
			};
			this.executeUpdateOptions = options; 
		},
		executePwdUpdate(idx, pwd){
			let param = new Object();
			param.idx = idx;
			param.password = pwd;

			this.$cAxios.post(this.prefix+"/idpw/replace",JSON.stringify(param),{
				headers:{
					'Content-Type':'application/json'
				}
			}).then(()=>{
				alert("변경되었습니다.");
				this.executeUpdateOptions = {};
				this.visible4 = false;
				this.pwdChangeYn = false;
				this.authInquriy();
			}).catch((err)=>{
				if(err.response){
					alert(err.response.data.message);
				}
			});
		},
		certUpdate(){
			this.executeUpdateOptions = {
				updateYN:true,
				type:'CERT'
			}
		},
		executeCertUpdate(idx){
			this.loadDoc('setup', null, idx.toString());
		},
		loadDoc (s_op, s_inJson, authIdx) {
					
			let loadNxClient = (v_s_op, v_s_inJson) => {
				
				let sUrl = "https://127.0.0.1:16565/?op=";
				if(v_s_op == 'certSelect'){
					sUrl = "https://127.0.0.1:16566/?op=";
				}

				this.$cAxios.post(sUrl+v_s_op, v_s_inJson ,{
					crossDomain: true,
					headers:{
						'Content-type':'application/json; charset=UTF-8',
					},
				}).then((res) =>{
					result(v_s_op, res.data);
				}).catch((err) => {
					if(err.request){ 
						if(err.config.url.indexOf('?op=setup') != -1){
							alert("공인인증서 등록 프로그램 설치가 필요합니다.");
							fnNxDownload();
						}
					}

				}).finally(() => {
				});

			}

			//첫 로드
			loadNxClient(s_op, s_inJson);
			var sendData = new Object();
			let result = (s_op, data) => {
				var errYn = data.errYn;
				
				if('setup' == s_op){
					if (errYn == 'N') {
						loadNxClient('certSelect', '{"certImageUrl": "", "nxKeypad": ""}');
					}
				} else if ('certSelect' == s_op) {
					sendData.certNm = data.cert_nm; //인증서명
					sendData.certPubDate = data.pub_dt;
					sendData.certEndDate = data.end_dt;
					sendData.certOrgNm = data.org_nm;//인증기관명 (yessign 등...)
					sendData.certPolicyId = data.oid;
					sendData.certSerialNo = data.sn;
					sendData.password = data.cert_pw;

					callExecute(data);
				} else if ('execute' == s_op) {
					if (errYn == 'N') {
						sendData.signCert = data.DER2PEM;
						sendData.signPri = data.KEY2PEM;
						sendData.idx = authIdx; 
						sendCertData(JSON.stringify(sendData));
					}
				}
				

			}


			let callExecute = (data) => {
				var inJson = new Object();
				inJson.orgCd = 'common';
				inJson.svcCd = 'getCertInfo';
				inJson.appCd = 'ant';
				inJson.signCert = data.file1; 
				inJson.signPri = data.file2;
				inJson.signPw = data.cert_pw;

				loadNxClient('execute', JSON.stringify(inJson));
			}

			let sendCertData = (sendData) => {  
				this.$cAxios.post(this.prefix + "/cert/replace", sendData,{
					headers : {
						'Content-Type':'application/json'
					}
				}).then(()=>{
					alert("변경되었습니다.");
					this.visible3 = false;
					this.authInquriy();
				}).catch((err)=>{
					if(err.response){
						alert(err.response.data.message);
					}
				});

			}

			let fnNxDownload = () => {
				location.href = "/files/ExAdapter_Web_Setup_20200611.exe";
			}

			
		},
		openSimpleAccountRegPopup() {
			this.bankRegInit = true;
			this.visible5 = true;
		},
		closeAccountRegPopup(val){
			this.visible2 = val;
			this.authInquriy();
		},
		closeSimpleAccountRegPopup(val){
			this.visible5 = val;
			this.authInquriy();
		}
	},
	mounted: function(){
		this.getBankCompanyList('');
	}
}
</script>